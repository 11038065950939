

























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { Route } from 'vue-router/types/router';
import { checkAccessType } from '@/helpers/accessType';
import { ERR_DEFAULT_MSG } from '@/helpers/errorHandler';
import { prepareVariablesForSingleEntityQuery, redirectToSingleEntityRoute } from '@/helpers/graphqlHelper';

import historicalSiteQuery from '@/graphql/historicalSite/HistoricalSite.single.query.gql';
import addToUserFavoritesMutation from '@/graphql/me/favorite/AddToUserFavorites.mutation.gql';
import deleteFromUserFavoritesMutation from '@/graphql/me/favorite/DeleteFromUserFavorites.mutation.gql';
import cancelBookingMutation from '@/graphql/booking/CancelBooking.mutation.gql';

import BackHeader from '@/components/partials/BackHeader.vue';
import DataItemInfo from '@/components/partials/DataItemInfo.vue';
import EntityPageTabs from '@/components/partials/EntityPageTabs.vue';
import FindOnMapAction from '@/components/partials/actions/FindOnMapAction.vue';
import AddToFavoriteAction from '@/components/partials/actions/AddToFavoriteAction.vue';
import AddToGuideAction from '@/components/partials/actions/AddToGuideAction.vue';
import MsgEntityNotFound from '@/components/MsgEntityNotFound.vue';
import EntityDetailsMap from '@/components/EntityDetailsMap.vue';
import AppConfirmationModal from '@/components/modals/AppConfirmationModal.vue';

@Component({
  components: {
    AppConfirmationModal,
    EntityDetailsMap,
    MsgEntityNotFound,
    EntityPageTabs,
    BackHeader,
    DataItemInfo,
    FindOnMapAction,
    AddToFavoriteAction,
    AddToGuideAction,
    AppRelatedSection: () => import('@/components/partials/AppRelatedSection.vue'),
    FreeEntryFlag: () => import('@/components/partials/actions/FreeEntryFlag.vue'),
    TicketRequestAction: () => import('@/components/partials/actions/TicketRequestAction.vue'),
    UseCultivistCardAction: () => import('@/components/partials/actions/UseCultivistCardAction.vue'),
    AccessInfoModal: () => import('@/components/partials/AccessInfoModal.vue'),
    FindOnMap: () => import('@/components/partials/FindOnMap.vue'),
  },
  metaInfo() {
    return {
      title: this.item?.title || 'Historical Site',
    };
  },
  computed: {
    ...mapState(['isMobileScreen']),
  },
})
export default class HistoricalSitePage extends Vue {
  public tabs: string[] = ['details'];
  public item: any = null;
  public itemFetching = true;
  public relatedRecords: any = [];
  public isFavoriteState = false;
  public isAccessInfoModal = false;
  public showUseCultCardModal = false;
  public isMobileScreen: boolean;
  public showFindOnMap = false;

  public isCalledCancelTourBookingAction = false;
  public isCalledUpdateTourBookingAction = false;
  public showConfirmCancelTourBookingModal = false;
  public showTourBookingForm = false;

  get accessTypeChecker() {
    return checkAccessType(this.item.accessType?.id);
  }

  get areAccessInstructionsPresent() {
    return !!this.item?.accessInstructions?.length;
  }

  get entityLatitude() {
    return parseFloat(this.item.latitude);
  }

  get entityLongitude() {
    return parseFloat(this.item.longitude);
  }

  get mediaUrl() {
    return this.item?.media?.[0]?.url;
  }

  get museumRole() {
    return this.$store.state.museumRole;
  }

  @Watch('$route')
  watchRouteChanged(newRoute: Route, oldRoute?: Route) {
    if (
      newRoute.path === oldRoute?.path &&
      ((oldRoute.query.hasOwnProperty('update-tour-booking') &&
        !newRoute.query.hasOwnProperty('update-tour-booking')) ||
        (oldRoute.query.hasOwnProperty('cancel-tour-booking') && !newRoute.query.hasOwnProperty('cancel-tour-booking')))
    ) {
      return;
    }

    this.tabs = ['details'];
    this.fetchHistoricalSite();
  }

  @Watch('showFindOnMap')
  watchShowFindOnMap(val: boolean) {
    if (val) {
      this.$store.dispatch('disableScroll');
    } else {
      this.$store.dispatch('enableScroll');
    }
  }

  created() {
    this.isCalledCancelTourBookingAction = this.$route.query.hasOwnProperty('cancel-tour-booking');
    this.isCalledUpdateTourBookingAction = this.$route.query.hasOwnProperty('update-tour-booking');
    this.fetchHistoricalSite();
  }

  resetTourBookingActions() {
    if (this.isCalledCancelTourBookingAction || this.isCalledUpdateTourBookingAction) {
      this.showConfirmCancelTourBookingModal = false;
      this.showTourBookingForm = false;
      this.isCalledCancelTourBookingAction = false;
      this.isCalledUpdateTourBookingAction = false;
      this.$router.replace({
        ...this.$route,
        name: this.$route.name || undefined,
        query: undefined,
      });
    }
  }

  handleCloseTourBookingForm() {
    this.resetTourBookingActions();
  }

  closeConfirmCancelTourBookingModal(answer: boolean) {
    if (!answer) {
      this.resetTourBookingActions();
      return;
    }

    this.$store.dispatch('showLoaderScreen', true);

    this.$apollo
      .mutate({
        mutation: cancelBookingMutation,
        variables: { id: this.item.activeTourBooking.id },
      })
      .then(() => {
        this.$store.dispatch('showLoaderScreen', false);
        this.item.activeTourBooking = null;
        this.$toast.success('Thank you, your booking has now been cancelled.');
        this.resetTourBookingActions();
      })
      .catch((e: any) => {
        this.$store.dispatch('showLoaderScreen', false);
        const error = e?.graphQLErrors?.[0]?.message || ERR_DEFAULT_MSG;
        this.$toast.error(error);
      });
  }

  fetchHistoricalSite() {
    const variables = prepareVariablesForSingleEntityQuery(this.$route.params);

    this.itemFetching = true;

    this.$apollo
      .query({
        query: historicalSiteQuery,
        fetchPolicy: 'no-cache',
        variables: {
          ...variables,
          destinationSlug: this.$route.params.destinationSlug,
        },
      })
      .then(({ data }: { data: any }) => {
        const item = data?.historicalSite || null;

        if (variables.onlyId && item?.id) {
          this.$router.replace(
            redirectToSingleEntityRoute(String(this.$route.name), item.id, variables.slug, {
              params: this.$route.params,
              query: this.$route.query,
            })
          );
          return;
        }

        this.item = data.historicalSite;

        if (!this.item) {
          this.itemFetching = false;
          return;
        }

        this.isFavoriteState = this.item.is_favorite_by_current_user;
        this.relatedRecords = this.item.relatedRecords;

        if (this.item.accessInstructions && this.item.accessInstructions.length) {
          this.tabs.push('access info');
        }
        this.itemFetching = false;

        if (this.isCalledCancelTourBookingAction || this.isCalledUpdateTourBookingAction) {
          if (!this.item?.activeTourBooking) {
            this.resetTourBookingActions();
          } else if (this.isCalledCancelTourBookingAction) {
            this.showConfirmCancelTourBookingModal = true;
          } else if (this.isCalledUpdateTourBookingAction) {
            this.showTourBookingForm = true;
          }
        }
      });
  }

  handleFavoriteAction() {
    if (!this.isFavoriteState) {
      this.$apollo
        .mutate({
          mutation: addToUserFavoritesMutation,
          variables: {
            entities: [{ id: this.item.id, type: this.item.__typename }],
          },
        })
        .then((result) => {
          this.isFavoriteState = result.data.addToUserFavorites;
        });

      return;
    }

    this.$apollo
      .mutate({
        mutation: deleteFromUserFavoritesMutation,
        variables: {
          entities: [{ id: this.item.id, type: this.item.__typename }],
        },
      })
      .then((result) => {
        this.isFavoriteState = !result.data.deleteFromUserFavorites;
      });
  }

  selectTab(tab: string) {
    if (tab === 'access info') {
      this.openAccessInfoModal();
    }
  }

  openAccessInfoModal() {
    if (this.areAccessInstructionsPresent) {
      this.isAccessInfoModal = true;
      this.$store.dispatch('disableScroll');
    }
  }

  closeAccessInfoModal() {
    const leaveScrollDisabled = this.showFindOnMap || (this.isMobileScreen && this.showUseCultCardModal);
    if (!leaveScrollDisabled) {
      this.$store.dispatch('enableScroll');
    }
  }
}
